(function () {
  'use strict';

  /* @ngdoc object
   * @name user.profile
   * @description
   *
   */
  angular
  .module('neo.home.user.profile', [
    'ui.router'
  ]);
}());
